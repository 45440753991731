.mainblock-info {
  width: 60%;
}
.mainblock-pic {
  width: 553px;
}

@media (max-width: 575.98px) {
  .mainblock-info,
  .mainblock-pic {
    width: 100%;
  }
  .mainblock-info {
    margin-bottom: 3rem !important;
  }
}
