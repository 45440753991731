.custom-card-desc {
  width: 100%;
  display: none !important;
  margin: 0.5rem;
  padding: 2rem;
  background: white;
  border: 0;
  border-radius: 12px;
  min-height: 230px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 9px 45px rgba(63, 61, 86, 0.1) !important;
  color: black !important;
}

.custom-card-desc.show {
  display: flex !important;
}

@media (max-width: 575.98px) {
  .custom-card-desc {
    min-height: 100px;
    margin: 0.2rem;
  }
}

.custom-card {
  margin: 0.5rem;
  padding: 1rem;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  box-sizing: border-box;
  border-radius: 12px;
  height: 230px;
  width: 350px;
  font-size: 24px;
}

.custom-card:hover {
  border-color: #e90057;
  color: black !important;
}

.custom-card.active {
  border: 0 !important;
  color: black !important;
  box-shadow: 0px 9px 45px rgba(63, 61, 86, 0.1) !important;
}

.custom-card-pic {
  margin-bottom: 1rem;
}

@media (max-width: 575.98px) {
  .custom-card {
    width: 145px;
    height: 100px;
    margin: 0.2rem;
    font-size: 12px;
  }
}
