.info-panel {
  width: 50%;
}
.pic-panel {
  width: 40%;
  padding: 2rem;
}

.article-header,
.article-prefix {
  margin-bottom: 1rem !important;
}

@media (max-width: 575.98px) {
  .info-panel {
    width: 100%;
    margin-bottom: 2rem;
  }
  .pic-panel {
    width: 100%;
  }
}
