.infoblock {
    text-align: center;
    width: 100%;
}

.infoblock-header {
    font-size: 48px;
    font-weight: 900;
    margin-bottom: 1rem;
}

.infoblock-children {
    margin-top: 3rem !important;
}

.infoblock-children svg {
    margin: auto !important;
}

@media (max-width: 575.98px) {
    .infoblock-header {
        width: 100%;
    }
    .infoblock-children {
        margin-top: 2rem !important;
    }
}
