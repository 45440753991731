@mixin white-gradient {
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
    );
}

$animationSpeed: 40s;

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-250px * 7));
    }
}

.slider {
    background: white;
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;

    &::before,
    &::after {
        @include white-gradient;
        content: "";
        height: 100px;
        position: absolute;
        width: 200px;
        z-index: 2;
    }

    &::after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }

    &::before {
        left: 0;
        top: 0;
    }

    .slide-track {
        animation: scroll $animationSpeed linear infinite;
        display: flex;
        width: calc(250px * 14);
    }

    .slide {
        height: 100px;
        width: 250px;
    }
}

@media (max-width: 575.98px) {
    .slider {
        height: 50px;

        &::before,
        &::after {
            @include white-gradient;
            height: 50px;
            width: 100px;
        }

        .slide-track {
            animation: scroll $animationSpeed linear infinite;
            display: flex;
            width: calc(125px * 14);
        }

        .slide,
        .slide > svg {
            height: 50px;
            width: 125px;
        }
    }
    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-125px * 7));
        }
    }
}
