.section {
    margin-top: 10rem !important;
}

.companies > img {
    margin: 1rem 0rem;
    max-width: 144px;
    max-height: 40px;
}

.prefix-text {
    color: gray;
    font-size: 24px;
}

.big-text {
    font-size: 56px;
    font-weight: 900;
}

.block-header-text {
    font-size: 48px;
    font-weight: 900;
}

.block-body-text {
    font-size: 32px;
    color: grey;
}

.text-blue {
    color: #021e74;
}

.text-pink {
    color: #e90057;
}

@media (max-width: 575.98px) {
    .big-text {
        font-size: 32px;
    }

    .prefix-text {
        font-size: 12px;
    }

    .block-header-text {
        font-size: 24px;
    }

    .block-body-text {
        font-size: 16px;
    }

    .section {
        margin-top: 5rem !important;
    }
}
