html body {
  font-family: Roboto;
  background-color: white;
}

body {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  margin: 0 auto;
  max-width: 100rem;
  padding: 2rem;
}

body div .row {
  margin: 0;
  padding: 0;
}

button {
  box-shadow: none !important;
  outline: 0px !important;
}

a {
  color: black;
  text-decoration: none !important;
}

a:active,
a:focus {
  color: #e90057 !important;
}

a:hover {
  color: gray !important;
}

.dropdown.show .btn {
  color: #e90057 !important;
}

.dropdown.show .btn {
  color: #e90057 !important;
}

button:focus {
  outline: none !important;
  background-color: transparent !important;
}

button:hover {
  color: #e90057 !important;
}

.dropdown-item {
  padding: 0.5rem;
}

.dropdown-item:nth-child(even),
.dropdown-item:last-child {
  border-top: 1px solid rgba(128, 128, 128, 0.5);
}

.dropdown-item:last-child {
  border-radius: 0 0 12px 12px;
}

.dropdown-item:first-child {
  border-radius: 12px 12px 0 0;
}

.dropdown-item:hover {
  color: #e90057 !important;
}

.dropdown-menu {
  background: #ffffff;
  box-shadow: 0px 9px 45px rgba(63, 61, 86, 0.1);
  border-radius: 12px;
  border: 0;
  min-width: 0rem;
  text-align: center;
  padding: 0;
}

.dropdown-toggle::after {
  display: none;
}
