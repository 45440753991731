.footer {
  width: 100%;
  margin-top: 10rem;
}

.footer-header {
  color: gray;
  padding: 0.5rem 0;
}

.footer-icons > a:hover svg {
  fill: #e90057 !important;
}
